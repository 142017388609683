const DesignConstants = {
    COLORS: {
        BACKGROUND_BLUE: "#e0ecff",
        BACKGROUND_BRIGHT_BLUE: "#f5f9ff",
    },
    SPACING: {
        DEV_BOX_HEIGHT: 14,
        PADDING_TOP: 20,
    },
};

export default DesignConstants;
