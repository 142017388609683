import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import DesignConstants from "../constants/DesignConstants";

function CodeTable({ accountCodes }) {
    const renderCodes = () => {
        let numCodes = 0;
        if (accountCodes && accountCodes.length > 0) {
            return accountCodes.map((codeInfo) => {
                numCodes++;
                const codeEntries = [];
                codeEntries.push(
                    <TableRow>
                        <TableCell>{numCodes}</TableCell>
                        <TableCell>{codeInfo.codeName}</TableCell>
                        <TableCell>{codeInfo.userMail}</TableCell>
                        <TableCell>{codeInfo.valid ? "true" : "false"}</TableCell>
                    </TableRow>,
                );
                return codeEntries;
            });
        } else {
            return (
                <TableRow>
                    <TableCell
                        style={{
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 10,
                        }}
                        align="center"
                        colSpan={4}
                    >
                        {"No codes yet"}
                    </TableCell>
                </TableRow>
            );
        }
    };

    return (
        <Table>
            <TableHead>
                <TableRow style={{ backgroundColor: DesignConstants.BACKGROUND_BLUE }}>
                    <TableCell>{"Nr."}</TableCell>
                    <TableCell>{"Code Name"}</TableCell>
                    <TableCell>{"User Mail"}</TableCell>
                    <TableCell>{"Code is Valid"}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>{renderCodes()}</TableBody>
        </Table>
    );
}

export default CodeTable;
