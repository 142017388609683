import React from "react";
import { Button, Collapse, Popover, Typography } from "@mui/material";

import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone";

function InfoAlert({
    infoTitle,
    text,
    content,
    show,
    setShow,
    anchorEl,
    buttonOneText,
    buttonOneAction,
    buttonOneIcon,
    buttonTwoText,
    buttonTwoAction,
    buttonTwoIcon,
    buttonThreeText,
    buttonThreeAction,
    buttonThreeIcon,
    anchorOriginH,
    anchorOriginV,
}) {
    return (
        <Collapse in={show}>
            <Popover
                anchorOrigin={{ horizontal: anchorOriginH ?? "center", vertical: anchorOriginV ?? "center" }}
                transformOrigin={{ horizontal: "center", vertical: "center" }}
                onClose={() => setShow(false)}
                anchorEl={anchorEl ?? undefined}
                open={show}
            >
                <div style={styles.popoverInnerContainer}>
                    <div style={styles.titleContainer}>
                        <WarningTwoToneIcon color={"warning"} style={styles.paddingRight10} />
                        <Typography variant="h6" color={"warning.main"} fontWeight={"bold"}>
                            {infoTitle}
                        </Typography>
                    </div>
                    <div style={styles.contentContainer}>
                        {text ? (
                            <Typography variant="subtitle" style={styles.marginBottom15}>
                                {text}
                            </Typography>
                        ) : (
                            <div />
                        )}
                        {content}
                        <div style={styles.buttonsContainer}>
                            <Button variant={"outlined"} onClick={buttonOneAction} style={styles.buttonContainer}>
                                {buttonOneIcon ? buttonOneIcon : <div />}
                                {buttonOneText ?? "OK"}
                            </Button>
                            {buttonTwoText && buttonTwoAction ? (
                                <Button variant={"outlined"} onClick={buttonTwoAction} style={styles.buttonContainer}>
                                    {buttonTwoIcon ? buttonTwoIcon : <div />}
                                    {buttonTwoText ?? "OK"}
                                </Button>
                            ) : (
                                <div />
                            )}
                            {buttonThreeText && buttonThreeAction ? (
                                <Button variant={"outlined"} onClick={buttonThreeAction} style={styles.buttonContainer}>
                                    {buttonThreeIcon ? buttonThreeIcon : <div />}
                                    {buttonThreeText ?? "OK"}
                                </Button>
                            ) : (
                                <div />
                            )}
                        </div>
                    </div>
                </div>
            </Popover>
        </Collapse>
    );
}

const styles = {
    popoverInnerContainer: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        display: "flex",
    },
    titleContainer: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        backgroundColor: "#fff5db",
        width: "100%",
        padding: 3,
        paddingLeft: 10,
    },
    paddingRight10: {
        paddingRight: 10,
    },
    contentContainer: {
        padding: 24,
        flexDirection: "column",
        display: "flex",
    },
    marginBottom15: {
        marginBottom: 15,
    },
    buttonsContainer: {
        display: "flex",
        flexDirection: "row",
    },
    buttonContainer: {
        marginBottom: 10,
        marginRight: 5,
        marginLeft: 5,
    },
};

export default InfoAlert;

