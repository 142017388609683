import Variant from "../config/live";

const ConfigConstants = {
    ...{
        // commnet out for local
        LINK_TO_BACKEND: "/admin/api/accounts/",
        LINK_TO_CODE_BACKEND: "/admin/api/codes/",
        LINK_TO_WEBINARS_BACKEND: "/admin/api/webinars/",

        // comment in for local
        // LINK_TO_BACKEND: "https:/bik-account-admin-dev.planetpop-qr.net/admin/api/accounts/",
        // LINK_TO_CODE_BACKEND: "https:/bik-account-admin-dev.planetpop-qr.net/admin/api/codes/",
        // LINK_TO_WEBINARS_BACKEND: "https:/bik-account-admin-dev.planetpop-qr.net/admin/api/webinars/",

        // comment in when you want to change routes
        // LINK_TO_BACKEND: "http://localhost:5004/admin/api/accounts/",
        // LINK_TO_CODE_BACKEND: "http://localhost:5004/admin/api/codes/",
        // LINK_TO_WEBINARS_BACKEND: "http://localhost:5004/admin/api/webinars/",
    },
    ...Variant,
};

export default ConfigConstants;
