import React, { useEffect } from "react";
import {
    Button,
    Checkbox,
    FormControlLabel,
    Popover,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { useState } from "react";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import CheckBoxTwoToneIcon from "@mui/icons-material/CheckBoxTwoTone";
import CheckBoxOutlineBlankTwoToneIcon from "@mui/icons-material/CheckBoxOutlineBlankTwoTone";
import { CSVLink } from "react-csv/lib";
import ConfigConstants from "../constants/ConfigConstants";
import DesignConstants from "../constants/DesignConstants";
import DatabaseService from "../helpers/DatabaseService";
import CodesHelper from "../helpers/CodesHelper";
import DatePickerP6PP from "../components/DatePicker";
import CodeTable from "../components/CodeTable";
import AddCodesPopover from "../components/AddCodesPopover";
import InfoAlert from "../components/InfoAlert";

function AccountOverview({ setSchoolAccount, setIsWebinarScreen }) {
    const [accountInfo, setAccountInfo] = useState({});
    const [currentAccountName, setCurrentAccountName] = useState("");
    const [morePopoverOpen, setMorePopoverOpen] = useState(false);
    const [addAccountPopoverOpen, setAddAccountPopoverOpen] = useState(false);
    const [addCodesPopoverOpen, setAddCodesPopoverOpen] = useState(false);
    const [codesPopoverOpen, setCodesPopoverOpen] = useState(false);
    const [schoolName, setSchoolName] = useState("");
    const [schoolAddress, setSchoolAddress] = useState("");
    const [description, setDescription] = useState("");
    const [accountName, setAccountName] = useState("");
    const [accountValid, setAccountValid] = useState(false);
    const [accountValidUntil, setAccountValidUntil] = useState(Date.now());
    const [numberNewCodes, setNumberNewCodes] = useState(0);
    const [accountCodes, setAccountCodes] = useState({});
    const accountRefObject = {};

    const [doyouReallyWantDelete, setDoYouReallyWantDelete] = useState(false);
    const [moreAnchorEl, setMoreAddCodesAnchorEl] = useState(null);
    const [accountNameError, setAccountNameError] = useState("");

    useEffect(() => {
        getAccountsFromDB();
    }, []);

    const getAccountsFromDB = async () => {
        const accountsData = await DatabaseService.getAccounts();
        if (accountsData) {
            const accountsDataObject = {};
            for (const account of accountsData) {
                accountsDataObject[account.accountName] = account;
                await getCodesForAccountFromDB(account.accountName);
            }
            setAccountInfo(accountsDataObject);
        }
    };

    const getCodesForAccountFromDB = async (accountName: string) => {
        const accountCodesData = await DatabaseService.getCodesForAccountFromDB(accountName);
        if (accountCodesData) {
            const newAccountCodes = accountCodes;
            newAccountCodes[accountName] = accountCodesData;
            setAccountCodes(newAccountCodes);
        }
    };

    const addNewCodes = async (validUntil) => {
        if (numberNewCodes > 0) {
            await CodesHelper.addNewCodesToDB(accountName, numberNewCodes, accountValid, validUntil);
            setNumberNewCodes(0);
        }
    };

    const createAccount = async () => {
        setAddAccountPopoverOpen(false);
        const validUntil = new Date(accountValidUntil).getTime();
        const newAccount = {
            schoolName,
            schoolAddress,
            description,
            accountName,
            creationDate: Date.now(),
            validUntil,
            valid: accountValid,
            codes: [],
        };

        await DatabaseService.createAccount(newAccount);
        await addNewCodes(validUntil);
        await getAccountsFromDB();
    };

    const changeValidity = async (accountName: string, validity: boolean) => {
        try {
            await DatabaseService.updateAccount(accountName, { valid: validity });
            await DatabaseService.updateCodes(accountName, { valid: validity });

            await getAccountsFromDB();
            await getCodesForAccountFromDB(accountName);
        } catch (e) {
            console.log("fetch failed with error ", e);
        }
    };

    const deleteAccount = async () => {
        await DatabaseService.deleteAccount(currentAccountName);
        setMorePopoverOpen(false);
        setCurrentAccountName("");
        await getAccountsFromDB();
        setDoYouReallyWantDelete(false);
    };

    const moreButtonClicked = (accountName: string) => {
        morePopoverOpen ? setCurrentAccountName("") : setCurrentAccountName(accountName);
        setMorePopoverOpen(!morePopoverOpen);
    };

    const handleCreateAccount = () => {
        if (!accountName) {
            setAccountNameError("Please don't leave this empty.");
        } else if (Object.values(accountInfo).find((account) => account.accountName === accountName)) {
            setAccountNameError("This name is already being used");
        } else {
            setAccountNameError("");
            createAccount();
        }
    };

    const renderAccounts = () => {
        return Object.values(accountInfo).map((account) => {
            const accountEntries = [];
            accountRefObject[account.accountName] = React.createRef();
            accountEntries.push(
                <TableRow
                    ref={accountRefObject[account.id]}
                    key={account.accountName?.replace(/ /g, "-")}
                    sx={
                        currentAccountName === account.accountName && morePopoverOpen
                            ? { backgroundColor: DesignConstants.BACKGROUND_BRIGHT_BLUE, border: 2, borderColor: DesignConstants.BACKGROUND_BLUE }
                            : {}
                    }
                >
                    <TableCell>{account.accountName}</TableCell>
                    <TableCell>{account.schoolName + " " + account.schoolAddress}</TableCell>
                    <TableCell>{account.description}</TableCell>
                    <TableCell>{new Date(account.validUntil).toDateString()}</TableCell>
                    <TableCell>{account.valid ? "true" : "false"}</TableCell>
                    <TableCell>{accountCodes[account.accountName] ? accountCodes[account.accountName].length : 0}</TableCell>
                    <TableCell>
                        <Button
                            variant={"outlined"}
                            onClick={(event) => {
                                setMoreAddCodesAnchorEl(event.currentTarget);
                                moreButtonClicked(account.accountName, account._id);
                            }}
                        >
                            {"..."}
                        </Button>
                    </TableCell>
                    <TableCell>
                        <Button
                            onClick={() => {
                                setSchoolAccount(account);
                            }}
                        >
                            <EditTwoToneIcon color="primary" />
                        </Button>
                    </TableCell>
                    {renderMorePopover(account.accountName, account.valid)}
                </TableRow>,
            );
            return accountEntries;
        });
    };

    const renderMorePopover = () => {
        if (morePopoverOpen) {
            const currentAccountValid = accountInfo[currentAccountName].valid;
            const validityString = currentAccountValid ? "invalid" : "valid";
            const changeValidityButtonName = "Set to " + validityString;
            const validityIcon = currentAccountValid ? (
                <CheckBoxTwoToneIcon color={"primary"} />
            ) : (
                <CheckBoxOutlineBlankTwoToneIcon color={"primary"} />
            );
            return (
                <Popover
                    anchorOrigin={{ horizontal: "center", vertical: "top" }}
                    transformOrigin={{ horizontal: "center", vertical: "top" }}
                    onClose={() => setMorePopoverOpen(false)}
                    anchorEl={moreAnchorEl}
                    open={morePopoverOpen}
                >
                    <div style={styles.morePopoverContainer}>
                        <div style={styles.moreButtonContainer}>
                            {validityIcon}
                            <Button
                                onClick={() => {
                                    changeValidity(currentAccountName, !currentAccountValid);
                                }}
                            >
                                {changeValidityButtonName}
                            </Button>
                        </div>
                        <div style={styles.moreButtonContainer}>
                            <VisibilityTwoToneIcon color={"primary"} />
                            <Button
                                onClick={() => {
                                    setCodesPopoverOpen(!codesPopoverOpen);
                                }}
                            >
                                {"See Codes"}
                            </Button>
                        </div>
                        <div style={styles.moreButtonContainer}>
                            <AddCircleTwoToneIcon color={"primary"} />
                            <Button
                                onClick={() => {
                                    setAddCodesPopoverOpen(!addCodesPopoverOpen);
                                }}
                            >
                                {"Create Codes"}
                            </Button>
                        </div>
                        <div style={styles.moreButtonContainer}>
                            <DeleteTwoToneIcon color={"primary"} />
                            <Button
                                onClick={() => {
                                    setDoYouReallyWantDelete(true);
                                }}
                            >
                                {"Delete Account"}
                            </Button>
                        </div>
                    </div>
                </Popover>
            );
        }
    };

    const renderAddAccountPopover = () => {
        return (
            <Popover
                anchorOrigin={{ horizontal: "center", vertical: "center" }}
                transformOrigin={{ horizontal: "center", vertical: "center" }}
                anchorEl={undefined}
                onClose={() => {
                    setAddAccountPopoverOpen(false);
                    setAccountNameError("");
                }}
                open={addAccountPopoverOpen}
            >
                <div style={styles.addAccountContainer}>
                    <Typography variant="h4" color={"primary"} style={styles.marginTop20}>
                        {"Create New School Account"}
                    </Typography>
                    <div style={styles.addAccountDataContainer}>
                        <TextField
                            label={"School Name"}
                            value={schoolName}
                            margin={"normal"}
                            onChange={(event) => setSchoolName(event.target.value)}
                            style={styles.newAccountTextField}
                        />
                        <TextField
                            label={"School Address"}
                            value={schoolAddress}
                            multiline
                            margin={"normal"}
                            onChange={(event) => setSchoolAddress(event.target.value)}
                            style={styles.newAccountTextField}
                        />
                        <TextField
                            label={"Description (optional)"}
                            value={description}
                            margin={"normal"}
                            onChange={(event) => setDescription(event.target.value)}
                            style={styles.newAccountTextField}
                        />
                        <TextField
                            label={"Account Name"}
                            value={accountName}
                            margin={"normal"}
                            onChange={(event) => setAccountName(event.target.value)}
                            style={styles.newAccountTextField}
                            error={accountNameError}
                            helperText={accountNameError}
                            required
                        />
                        <TextField
                            type={"number"}
                            label={"Number of New Codes"}
                            value={numberNewCodes}
                            margin={"normal"}
                            onChange={(event) => setNumberNewCodes(event.target.value)}
                            style={styles.newAccountTextField}
                        />
                        {renderDatePicker()}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={accountValid}
                                    onClick={() => {
                                        setAccountValid(!accountValid);
                                    }}
                                />
                            }
                            label={"Account is valid"}
                            style={styles.addAccountValidContainer}
                        />
                    </div>
                    <Button variant={"outlined"} style={styles.marginBottom24} onClick={() => handleCreateAccount()}>
                        <AddCircleTwoToneIcon style={styles.marginRight10} />
                        {"Create Account"}
                    </Button>
                </div>
            </Popover>
        );
    };

    const renderDatePicker = () => {
        return (
            <div style={{ ...styles.newAccountTextField, marginTop: "16px", marginBottom: "8px" }}>
                <DatePickerP6PP date={accountValidUntil} setDate={setAccountValidUntil} label={"Valid Until"} />
            </div>
        );
    };

    const renderAddCodesPopover = () => {
        return (
            <AddCodesPopover
                addCodesPopoverOpen={addCodesPopoverOpen}
                setAddCodesPopoverOpen={setAddCodesPopoverOpen}
                currentAccountName={currentAccountName}
                valid={accountInfo[currentAccountName]?.valid ?? false}
                validUntil={accountInfo[currentAccountName]?.validUntil ?? false}
                updateCodes={() => getCodesForAccountFromDB(currentAccountName)}
            />
        );
    };

    const renderCodesPopover = () => {
        if (codesPopoverOpen) {
            return (
                <Popover
                    anchorOrigin={{ horizontal: "center", vertical: "center" }}
                    transformOrigin={{ horizontal: "center", vertical: "center" }}
                    anchorEl={undefined}
                    onClose={() => setCodesPopoverOpen(false)}
                    open={codesPopoverOpen}
                    style={{ marginTop: 10 }}
                >
                    <div
                        style={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            display: "flex",
                        }}
                    >
                        <CodeTable accountCodes={accountCodes[currentAccountName]} />
                        <div style={styles.downloadCodesContainer}>
                            <CSVLink data={accountCodes[currentAccountName]}>DownloadCodes</CSVLink>
                        </div>
                    </div>
                </Popover>
            );
        }
    };

    const renderDeleteConfirmPopup = () => {
        return (
            <InfoAlert
                show={doyouReallyWantDelete}
                setShow={setDoYouReallyWantDelete}
                infoTitle={"Account Deletion"}
                text={"Do you really want to delete the account " + currentAccountName + "?"}
                buttonOneText={"Yes, delete"}
                buttonOneAction={deleteAccount}
                buttonOneIcon={<DeleteTwoToneIcon color={"primary"} style={styles.marginRight10} />}
                buttonTwoText={"Cancel"}
                buttonTwoAction={() => {
                    setDoYouReallyWantDelete(false);
                }}
                buttonTwoIcon={<CloseTwoToneIcon color={"primary"} />}
            />
        );
    };

    return (
        <div style={styles.container}>
            <Typography variant="h4" color={"primary"}>
                {ConfigConstants.TITLE}
            </Typography>
            <div style={styles.marginTop20}>
                <Button
                    variant={"outlined"}
                    onClick={() => {
                        setAddAccountPopoverOpen(!addAccountPopoverOpen);
                    }}
                >
                    <AddCircleTwoToneIcon />
                    <div style={{ marginLeft: 10 }}>{"Create School Account"}</div>
                </Button>
            </div>
            <div style={styles.marginTop20}>
                <Button variant={"contained"} onClick={() => setIsWebinarScreen(true)}>
                    <div style={{ marginRight: 10 }}>{"Webinars"}</div>
                    <ArrowForwardIcon />
                </Button>
            </div>
            <TableContainer style={{ maxWidth: "fit-content" }}>
                <Table style={styles.marginTop20} stickyHeader>
                    <TableHead>
                        <TableRow style={{ backgroundColor: DesignConstants.BACKGROUND_BLUE }}>
                            <TableCell>{"Account Name"}</TableCell>
                            <TableCell>{"School"}</TableCell>
                            <TableCell>{"Description"}</TableCell>
                            <TableCell>{"Valid Until"}</TableCell>
                            <TableCell>{"Valid"}</TableCell>
                            <TableCell>{"Number of Codes"}</TableCell>
                            <TableCell>{"More"}</TableCell>
                            <TableCell>{"Edit School"}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{renderAccounts()}</TableBody>
                </Table>
            </TableContainer>
            {renderAddAccountPopover()}
            {renderAddCodesPopover()}
            {renderCodesPopover()}
            {renderDeleteConfirmPopup()}
        </div>
    );
}

const styles = {
    container: {
        paddingTop: DesignConstants.SPACING.PADDING_TOP,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxHeight: `calc(100% - ${(ConfigConstants.IS_DEV ? DesignConstants.SPACING.DEV_BOX_HEIGHT : 0) + DesignConstants.SPACING.PADDING_TOP}px)`,
    },
    morePopoverContainer: {
        flexDirection: "column",
        display: "flex",
    },
    moreButtonContainer: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        padding: 5,
    },
    addAccountContainer: {
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
    },
    addAccountDataContainer: {
        padding: 24,
        flex: 1,
        flexWrap: "wrap",
        flexDirection: "row",
        alignItems: "flex-start",
        display: "flex",
        justifyContent: "center",
        maxWidth: 1000,
    },
    marginTop20: {
        marginTop: 20,
    },
    marginBottom24: {
        marginBottom: 24,
    },
    marginRight10: {
        marginRight: 10,
    },
    newAccountTextField: {
        marginLeft: 5,
        marginRight: 5,
    },
    addAccountValidContainer: {
        alignItems: "center",
        justifyContent: "center",
        marginTop: 24,
        marginLeft: 5,
        marinRight: 5,
    },
    downloadCodesContainer: {
        padding: 24,
        justifyContent: "center",
        alignItems: "center",
    },
};

export default AccountOverview;
