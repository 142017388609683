import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    ButtonGroup,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
} from "@mui/material";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";

import ExitButton from "../components/ExitButton";
import DateTimePickerP6PP from "../components/DateTimePicker";
import InfoAlert from "../components/InfoAlert";

import DatabaseService from "../helpers/DatabaseService";
import DesignConstants from "../constants/DesignConstants";

const timeOptions = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
};

const initialWebinarState = {
    _id: "",
    webinarName: "",
    webinarDateTime: "",
    publishDateTime: "",
};

function Webinars({ setIsWebinarScreen }) {
    const webinarRefObject = {};
    const [webinars, setWebinars] = useState([]);
    const [currentWebinar, setCurrentWebinar] = useState(initialWebinarState);

    const [isAddOrEditPopupOpen, setIsAddOrEditPopupOpen] = useState(false);
    const [doyouReallyWantDelete, setDoYouReallyWantDelete] = useState(false);

    useEffect(() => {
        getWebinarsFromDB();
    }, []);

    const getWebinarsFromDB = async () => {
        const webinarsData = await DatabaseService.getWebinars();
        if (webinarsData) {
            setWebinars(webinarsData);
        }
    };

    const createWebinar = async ({ webinarName, publishDateTime, webinarDateTime }) => {
        const newWebinar = {
            webinarName,
            publishDateTime,
            webinarDateTime,
        };

        await DatabaseService.createWebinar(newWebinar);
        await getWebinarsFromDB();
        setIsAddOrEditPopupOpen(false);
    };

    const updateWebinar = async (changedWebinar) => {
        const { _id, ...rest } = changedWebinar;
        try {
            await DatabaseService.updateWebinar(_id, rest);
            await getWebinarsFromDB();
        } catch (e) {
            console.log("fetch failed with error ", e);
        }
    };

    const deleteWebinar = async (id) => {
        await DatabaseService.deleteWebinar(id);
        await getWebinarsFromDB();
        handleCloseDeletePopup();
    };

    const handleSave = async () => {
        const { _id, webinarName, publishDateTime, webinarDateTime } = currentWebinar;
        if (!(webinarName && publishDateTime && webinarDateTime)) return;

        _id ? await updateWebinar(currentWebinar) : await createWebinar(currentWebinar);

        setIsAddOrEditPopupOpen(false);
        resetCurrentWebinar();
    };

    const resetCurrentWebinar = () => setCurrentWebinar(initialWebinarState);

    const handleExit = () => setIsWebinarScreen(false);

    const handleCloseAddOrEditPopup = () => {
        setIsAddOrEditPopupOpen(false);
        resetCurrentWebinar();
    };

    const handleCloseDeletePopup = () => {
        setDoYouReallyWantDelete(false);
        resetCurrentWebinar();
    };

    const renderWebinarEntries = (webinarEntries) => {
        return webinarEntries.map((webinar) => {
            webinarRefObject[webinar.webinarName] = React.createRef();
            return (
                <TableRow ref={webinarRefObject[webinar?._id]} key={webinar?.webinarName?.replace(/ /g, "-")}>
                    <TableCell>{webinar?.webinarName}</TableCell>
                    <TableCell>{webinar?.publishDateTime ? new Date(webinar.publishDateTime).toLocaleString("de-DE", timeOptions) : ""}</TableCell>
                    <TableCell>{webinar?.webinarDateTime ? new Date(webinar.webinarDateTime).toLocaleString("de-DE", timeOptions) : ""}</TableCell>
                    <TableCell sx={styles.tableRowActions}>
                        <ButtonGroup variant="text" aria-label="text button group">
                            <Button
                                onClick={() => {
                                    setCurrentWebinar(webinar);
                                    setIsAddOrEditPopupOpen(true);
                                }}
                            >
                                <EditTwoToneIcon color="primary" />
                            </Button>
                            <Button
                                onClick={() => {
                                    setCurrentWebinar(webinar);
                                    setDoYouReallyWantDelete(true);
                                }}
                            >
                                <DeleteIcon color="primary" />
                            </Button>
                        </ButtonGroup>
                    </TableCell>
                </TableRow>
            );
        });
    };

    const renderWebinarTable = (title = "", webinarEntries) => {
        return (
            <Box sx={styles.box}>
                <Typography variant="h5" color={"primary"}>
                    {title}
                </Typography>
                <Table style={styles.marginTop20}>
                    <TableHead>
                        <TableRow style={{ backgroundColor: DesignConstants.BACKGROUND_BLUE }}>
                            <TableCell>{"Name"}</TableCell>
                            <TableCell>{"Publishing date & time"}</TableCell>
                            <TableCell>{"Date & time"}</TableCell>
                            <TableCell>{""}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{renderWebinarEntries(webinarEntries)}</TableBody>
                </Table>
            </Box>
        );
    };

    const renderWebinars = () => {
        const now = new Date();
        const upcomingWebinars = [];
        const pastWebinars = [];
        if (webinars.length > 0) {
            const sortedWebinars = webinars.sort((a, b) => (new Date(a.webinarDateTime) > new Date(b.webinarDateTime) ? -1 : +1));
            sortedWebinars.forEach((webinar) => {
                new Date(webinar.webinarDateTime) > now ? upcomingWebinars.push(webinar) : pastWebinars.push(webinar);
            });
        }

        return (
            <>
                {renderWebinarTable("Upcoming Webinars", upcomingWebinars)}
                {renderWebinarTable("Past Webinars", pastWebinars)}
            </>
        );
    };

    const renderAddOrEditPopup = () => {
        if (isAddOrEditPopupOpen && (!currentWebinar.publishDateTime || !currentWebinar.webinarDateTime)) {
            setCurrentWebinar((prev) => {
                return {
                    ...prev,
                    publishDateTime: currentWebinar.publishDateTime !== "" ? currentWebinar.publishDateTime : new Date(),
                    webinarDateTime: currentWebinar.webinarDateTime !== "" ? currentWebinar.webinarDateTime : new Date(),
                };
            });
        }
        return (
            <Dialog fullWidth={true} maxWidth="md" open={isAddOrEditPopupOpen} onClose={handleCloseAddOrEditPopup} sx={styles.width100}>
                <DialogTitle sx={{ m: 0, p: 2 }}>{currentWebinar._id ? "Edit Webinar" : "Add Webinar"}</DialogTitle>
                <IconButton aria-label="close" onClick={handleCloseAddOrEditPopup} sx={styles.topRightButton}>
                    <CloseTwoToneIcon />
                </IconButton>
                <DialogContent dividers sx={styles.flexJustifyBetween}>
                    <TextField
                        id="name"
                        error={!currentWebinar.webinarName}
                        label="name"
                        placeholder="webinar name"
                        value={currentWebinar.webinarName}
                        onChange={(e) =>
                            setCurrentWebinar((prev) => {
                                return { ...prev, webinarName: e.target.value };
                            })
                        }
                        variant="filled"
                        required
                        sx={styles.marginX2}
                    />
                    <DateTimePickerP6PP
                        dateTime={currentWebinar.publishDateTime}
                        setDateTime={(dateTime) =>
                            setCurrentWebinar((prev) => {
                                return { ...prev, publishDateTime: dateTime };
                            })
                        }
                        label={"publishing date & time"}
                        styles={{ ...styles.marginLeftt5, ...styles.marginRight5 }}
                    />
                    <DateTimePickerP6PP
                        dateTime={currentWebinar.webinarDateTime}
                        setDateTime={(dateTime) => {
                            setCurrentWebinar((prev) => {
                                return { ...prev, webinarDateTime: dateTime };
                            });
                        }}
                        label={"webinar date & time"}
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleSave}>
                        {currentWebinar._id ? "Save changes" : "Save"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const renderDeleteConfirmPopup = () => {
        const name = currentWebinar.webinarName;
        const dateTime = new Date(currentWebinar.webinarDateTime).toLocaleString("de-DE", timeOptions);
        const content = <Typography mb={2}>{`"${name}", ${dateTime} ?`}</Typography>;
        return (
            <InfoAlert
                show={doyouReallyWantDelete}
                setShow={handleCloseDeletePopup}
                infoTitle={"Webinar Deletion"}
                text={`Do you really want to delete the webinar`}
                content={content}
                buttonOneText={"Yes, delete"}
                buttonOneAction={() => deleteWebinar(currentWebinar._id)}
                buttonOneIcon={<DeleteTwoToneIcon color={"primary"} style={styles.marginRight5} />}
                buttonTwoText={"Cancel"}
                buttonTwoAction={handleCloseDeletePopup}
                buttonTwoIcon={<CloseTwoToneIcon color={"primary"} />}
            />
        );
    };

    return (
        <div style={styles.outerContainer}>
            <ExitButton onClick={handleExit} />
            <Typography variant="h4" color={"primary"} fontWeight={"bold"}>
                Webinars
            </Typography>
            <div style={styles.marginTop20}>
                <Button variant={"outlined"} onClick={() => setIsAddOrEditPopupOpen(true)}>
                    <AddCircleTwoToneIcon />
                    <div style={styles.marginLeft10}>{"Add Webinar"}</div>
                </Button>
            </div>
            {renderWebinars()}
            {isAddOrEditPopupOpen && renderAddOrEditPopup()}
            {doyouReallyWantDelete && renderDeleteConfirmPopup()}
        </div>
    );
}

const styles = {
    box: {
        width: "90%",
        margin: "2rem",
        padding: "2rem",
    },
    flexJustifyBetween: {
        display: "flex",
        justifyContent: "space-between",
    },
    tableRowActions: {
        display: "flex",
        justifyContent: "flex-end",
    },
    outerContainer: {
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 20,
    },
    topRightButton: {
        position: "absolute",
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
    },
    width100: {
        width: "100%",
    },
    marginTop20: {
        marginTop: 20,
    },
    marginX2: {
        mx: 2,
    },
    marginRight5: {
        marginRight: 5,
    },
    marginLeft10: {
        marginLeft: 10,
    },
    marginLeftt5: {
        marginLeft: 5,
    },
};

export default Webinars;
